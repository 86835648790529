








































































































































































import { Component, Vue } from 'vue-property-decorator';
import { 
  dispatchGetLucidSettings, 
  dispatchUpdateLucidSettings, 
  dispatchGetProlificSettings, 
  dispatchUpdateProlificSettings 
} from '@/store/admin/actions';
import { readLucidSettings, readProlificSettings } from '@/store/admin/getters';
import { ILucidSettings, IProlificSettings } from '@/interfaces';

@Component
export default class AdminLucid extends Vue {
  // Lucid
  public Lucid_QuotaCPI_FR_1Q = 0.01;
  public Lucid_QuotaCPI_FR_2Q = 0.01;
  public Lucid_QuotaCPI_FR_3Q = 0.01;
  public Lucid_QuotaCPI_SE_1Q = 0.01;
  public Lucid_QuotaCPI_SE_2Q = 0.01;
  public Lucid_QuotaCPI_SE_3Q = 0.01;
  public Lucid_QuotaCPI_CN_1Q = 0.01;
  public Lucid_QuotaCPI_CN_2Q = 0.01;
  public Lucid_QuotaCPI_CN_3Q = 0.01;
  // Prolific
  public Prolific_QuotaCPI_UK_1M = 0.15;
  public Prolific_QuotaCPI_US_1M = 0.15;

  public async mounted() {
    await dispatchGetLucidSettings(this.$store);
    await dispatchGetProlificSettings(this.$store);
    this.reset();
  }

  public reset() {
    // reset lucid settings
    this.Lucid_QuotaCPI_FR_1Q = this.lucidSettings.quotaCPI_FR_1Q;
    this.Lucid_QuotaCPI_FR_2Q = this.lucidSettings.quotaCPI_FR_2Q;
    this.Lucid_QuotaCPI_FR_3Q = this.lucidSettings.quotaCPI_FR_3Q;
    this.Lucid_QuotaCPI_SE_1Q = this.lucidSettings.quotaCPI_SE_1Q;
    this.Lucid_QuotaCPI_SE_2Q = this.lucidSettings.quotaCPI_SE_2Q;
    this.Lucid_QuotaCPI_SE_3Q = this.lucidSettings.quotaCPI_SE_3Q;
    this.Lucid_QuotaCPI_CN_1Q = this.lucidSettings.quotaCPI_CN_1Q;
    this.Lucid_QuotaCPI_CN_2Q = this.lucidSettings.quotaCPI_CN_2Q;
    this.Lucid_QuotaCPI_CN_3Q = this.lucidSettings.quotaCPI_CN_3Q;
    
    // reset prolific settings
    this.Prolific_QuotaCPI_UK_1M = this.prolificSettings.quotaCPI_UK_1M;
    this.Prolific_QuotaCPI_US_1M = this.prolificSettings.quotaCPI_US_1M;

    // this.savedVal = this.lucidSettings.quotaCPI;
  }

  get lucidSettings() {
    return readLucidSettings(this.$store);
  }

  get prolificSettings() {
    return readProlificSettings(this.$store);
  }

  public async save() {
    if (await this.$validator.validateAll()) {
      const updatedLucidProfile: ILucidSettings = {
        quotaCPI_FR_1Q: this.Lucid_QuotaCPI_FR_1Q,
        quotaCPI_FR_2Q: this.Lucid_QuotaCPI_FR_2Q,
        quotaCPI_FR_3Q: this.Lucid_QuotaCPI_FR_3Q,
        quotaCPI_SE_1Q: this.Lucid_QuotaCPI_SE_1Q,
        quotaCPI_SE_2Q: this.Lucid_QuotaCPI_SE_2Q,
        quotaCPI_SE_3Q: this.Lucid_QuotaCPI_SE_3Q,
        quotaCPI_CN_1Q: this.Lucid_QuotaCPI_CN_1Q,
        quotaCPI_CN_2Q: this.Lucid_QuotaCPI_CN_2Q,
        quotaCPI_CN_3Q: this.Lucid_QuotaCPI_CN_3Q,
      };
      await dispatchUpdateLucidSettings(this.$store, updatedLucidProfile);

      const updatedProlificProfile: IProlificSettings = {
        quotaCPI_UK_1M: this.Prolific_QuotaCPI_UK_1M,
        quotaCPI_US_1M: this.Prolific_QuotaCPI_US_1M,
      };
      await dispatchUpdateProlificSettings(this.$store, updatedProlificProfile);
      this.reset();
    }
  }
}
